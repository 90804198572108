.modal {
    width: 80%;
    max-height: 80%;
    min-height: 30%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    padding: 24px;
    z-index: 3;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 1s, visibility 1s;
    opacity: 0;
    visibility: hidden;
    overflow-y: scroll;
    border: 1px solid rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(30px);
    box-shadow: 0 3px rgba(0, 0, 0, 0.1);
    color: #f9748f;
}

.modal.active {
    opacity: 1;
    visibility: visible;
}

.overlay {
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    transition: opacity 1s, visibility 1s;
    opacity: 0;
    visibility: hidden;
}

.overlay.active {
    opacity: 0.6;
    visibility: visible;
}

.close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #f9748f;
    border: 1px solid #f9748f;
    border-radius: 10px;
}

p.small {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
}

ol.order {
    list-style-type: lower-alpha;
}

li.list {
    font-size: 14px;
    margin-bottom: 10px;
}

p.note {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 15px;
}

.containerInput {
    margin: 30px auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

input.nameInput {
    width: 90%;
    padding: 1px 1px;
    background-color: #ffffff;
    height: 50px;
    border: none;
    outline: none;
}

.containerExec {
    margin: 20px auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.exec {
    color: #fff;
    align-items: center;
    width: 80%;
    height: 30px;
    background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
    /* background-image: linear-gradient(150deg, transparent 1%, rgba(255, 255, 255, 0.3) 100%); */
    border-radius: 32px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(30px);
}
