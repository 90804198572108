@import './variables';

body {
    // background: linear-gradient(to top left, #e68b28, #eba85e);
}

.container {
    // background: linear-gradient(to top left, #e68b28, #eba85e);
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    height: 100%;
}
ul.item {
    padding-top: 20px;
    padding-bottom: 30px;
    overflow-y: scroll;
    list-style: none;
    display: flex;
    max-width: 960px;
    flex-direction: column;
    gap: 20px 0;
    height: 95%;
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}

.list {
    min-width: 320px;
    min-height: 130px;
    width: 100%;
    background-image: linear-gradient(150deg, transparent 1%, rgba(255, 255, 255, 0.5) 100%);
    border-radius: 30px;
    border: 3px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(30px);
    box-shadow: 0 2px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    display: flex;
    align-items: center;
}

.list:hover {
    opacity: 0.8;
}

div.image {
    min-width: 120px;
    margin-left: 20px;
    display: flex;
    // margin: 4rem auto auto;
}

div.image img {
    width: 110px;
    height: 110px;
    border-radius: 200px;
    object-fit: cover;
    border: solid 2px #fff;
    // filter: drop-shadow(20px 10px 15px rgba(0, 0, 0, 0.2));
}

.image.noImage {
    position: relative;
    display: inline-block;
}

.image.noImage img {
    background-color: #fff;
    width: 110px;
    height: 110px;
    border-radius: 200px;
    opacity: 0.3;
    object-fit: cover;
}

.image.noImage p {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #562E00;
    margin: 0;
    border-radius: 5px;
    text-align: center;
}

.content {
    padding: 0 0.5rem;
}

h1.title {
    font-size: 1rem;
    color: #fff;
    text-align: center;
}

h3.title {
    color: #562E00;
    font-size: 0.9rem;
    margin-block-end: 0;
}

.date {
    margin-bottom: 0.5rem;

    >time {
        color: #{$cacao};
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #{$cacao-light};
    }
}

.inputItem {
    background: none;
    border: none;
    border-bottom: solid 1px #{$cacao-light};
    font-size: 0.9rem;
    font-weight: 700;
}

.reactIcons{
    color: #{$secondary-color};
}