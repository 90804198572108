@import '../variables';

.lock-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  position: relative;
  border-radius: 50%;
  background-color: transparent;
  overflow: hidden;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  border: 1px solid rgb(231,153,096);

  input[type="checkbox"] {
    display: none;
  }

  .icon {
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 10px;
      transition: transform 0.3s ease;
      background-size: 80%;
      background-position: center;
      background-image: var(--lock-open-icon);
      transform: scale(1);
    }
  }

  input[type="checkbox"]:checked + .icon:before {
    background-image: var(--lock-close-icon);
    transform: scale(1);
  }
}
