.gridContainer {
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    padding: 0 10px 40px;
    margin-bottom: -10px;
}

.gridItem {
    width: 100%;
    padding-top: 100%;
    position: relative;
}

.gridItem img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}