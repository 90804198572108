@import './variables';


/* --------------------------------------
  Default Styling
-------------------------------------- */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  // background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  // background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
  // background-image: linear-gradient(to top, lightgrey 0%, lightgrey 1%, #e0e0e0 26%, #efefef 48%, #d9d9d9 75%, #bcbcbc 100%);
  // background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
  background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
  background-blend-mode: multiply, multiply;
  color: #562e00;
  font-family: "Helvetica Neue", "Helvetica", "Hiragino Sans",
    "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
}

h1,
h2 {
  line-height: 1.4;
}

h2,
h3 {
  margin-top: 0;
  font-weight: 700;
}

h1 {
  font-size: 0.9rem;
  margin-top: 0;
  font-weight: 500;
  color: #{$white};
}

h2 {
  text-align: center;
}

ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

p {
  line-height: 1.4;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

a,
input,
textarea {
  transition: all 0.15s ease-in-out;
}

input {
  outline: none;
}

input[type="submit"]:hover {
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

/* --------------------------------------
  Reusable Classes
-------------------------------------- */

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.text8 {
  font-size: 8px !important;
}

.text12 {
  font-size: 12px !important;
}

.text14 {
  font-size: 14px !important;
}

.text16 {
  font-size: 16px !important;
}

.color-white {
  color: #fff;
}

.hide {
  display: none !important;
}

footer {
  z-index: 10;
  position: sticky;
  bottom: 0;
}