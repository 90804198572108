/* --------------------------------------
  Mobile Styling
-------------------------------------- */
:root {
  --header-height: 30px;
  --footer-height: 60px;
}

/* --------------------------------------
Reusable Classes
-------------------------------------- */



/* --------------------------------------
Header
-------------------------------------- */

.header {
  height: var(--header-height);
  padding: 20px 20px;
  /* background-color: #f6ad3c; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-logo-img {
  width: 40px;
}

.header-logo-title {
  color: #ffffff;
}

/* --------------------------------------
Main - List
-------------------------------------- */

main {
  height: calc(100vh - var(--footer-height));
}

.container {
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

.list-title {
  flex: 0.5;
  text-align: center;
  margin-bottom: 0;
}

.list-container {
  flex: 9.5;
  border-top: 0.3px solid #fff;
  padding: 20px 20px;
  overflow-y: scroll;
  margin-bottom: 30px;
}

.list-container li {
  margin-bottom: 10px;
  padding: 5px 5px;
  border-radius: 3px;
  background-image: linear-gradient(150deg, transparent 1%, rgba(255, 255, 255, 0.5) 100%);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

/* Hide default checkbox */
input[type="checkbox"] {
    display: none;
}

label {
  padding: 3px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 35px; */
  max-height: 75px;
  gap: 10px;
  cursor: pointer;
}

/* Wrapper for checkbox and text */
.checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Public label */
.checkbox-label.public {
  color: #f6ad3c;
}

/* Create custom checkbox */
.custom-checkbox {
  position: relative;
  min-width: 26px;
  min-height: 26px;
  border: 1px solid rgb(231,153,096);
  cursor: pointer;
  border-radius: 3px;
}
.custom-checkbox.disabled {
  opacity: 0.2;
}


/* Checkmark */
.custom-checkbox::after,
.custom-checkbox.disabled::after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  border-color: rgb(231,153,096);
}
.custom-checkbox.disabled::after {
  opacity: 0.2;
}

/* Animation circle */
.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(086, 046, 000, 0.7);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.7s ease, height 0.7s ease, opacity 0.2s ease 0.7s;
}

/* Public animation circle */
.checkbox-label.public .custom-checkbox::before {
  background-color: rgba(108, 222, 255, 0.7);
}

.item-name {
  padding-left: 2px;
  font-size: 16px;
  color: rgb(230,217,219);
  color: rgb(140,110,168);
  color: rgb(175,159,141);
  color: rgb(102,086,086);
  color: rgb(181,157,161);
  color: rgb(252,190,097);
  color: rgb(247,117,103);
  color: rgb(228,184,138);
  color: rgb(231,153,096);
}

/* Animation for expansion */
@keyframes expand {
  0% {
    width: 0;
    height: 0;
    opacity: 0.7;
  }

  70% {
    width: 26px;
    height: 26px;
    opacity: 0.5;
  }

  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

/* Checked state */
input[type="checkbox"]:checked+.custom-checkbox::after {
  display: block;
}

input[type="checkbox"]:checked+.custom-checkbox::before {
  animation: expand 0.9s forwards;
}


@keyframes expand {
  from {
      transform: scale(0);
  }
  to {
      transform: scale(1);
  }
}

/* --------------------------------------
Footer
-------------------------------------- */

.footer {
  height: var(--footer-height);
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 5px;
}

.function {
  flex: 3;
  display: flex;
  justify-content: space-around;
}
.function.focus {
  flex: 1;
}


.exec {
  flex: 2;
  display: flex;
  justify-content: center;
}


.icon-button img {
  width: 22px;
  height: 22px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.icon-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-bottom: 10px;
  position: relative;
  display: inline-block;
  font-weight: bold;
  padding: 0.25em 0;
  text-decoration: none;
  margin: 0 2px;
  background-color: #fff;
  backdrop-filter: blur(30px);
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
}

.icon-button.active {
  background: rgb(249,194, 112);
}

.icon-button:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 5px;
  top: 100%;
  left: 0;
  border-radius: 3px;
  transition: .2s;
  background-color: #fff;
  backdrop-filter: blur(30px);
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
}

.icon-button.active:before {
  background: rgb(249,194, 112);
}

.icon-button.active:before {
  top: -webkit-calc(100% - 3px);
  top: calc(100% - 3px);
}
.interface {
  flex: 5;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
}

.input-group {
  width: 90%;
}

.input-item {
  width: 100%;
  font-size: 16px;
  background: none;
  border: none;
  color: rgb(231,153,096);
  margin-bottom: 0;
}

.underline {
  position: relative;
}

.underline::before,
.underline::after {
  position: absolute;
  bottom: 0px;
  width: 0px;
  height: 1px;
  content: '';
  background-color: rgb(231,153,096);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.underline::before {
  left: 50%;
}

.underline::after {
  right: 50%;
}

.input-item:focus+.underline::before,
.input-item:focus+.underline::after {
  width: 50%;
}

.exec-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 30px;
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
  /* background-image: linear-gradient(150deg, transparent 1%, rgba(255, 255, 255, 0.3) 100%); */
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
}

div.horizontaLine {
  margin-bottom: -30px;
  width: 100%;
  border-bottom: solid 0.1px #fff;
}

.suitcase-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 0.5px solid #fff;
  border-radius: 30px;
  margin-right: -10px;
}